<template>
  <div>
    <template v-if="preview">
      <slot name="header" :planners="planners">
        <v-card-subtitle class="d-flex pt-2">
          <p class="heading text-h6 mb-0">Planners</p>
          <v-spacer></v-spacer>
          <v-btn small class="mt-1" v-if="planners?.length" text :to="{ name: 'planners' }">
            {{ $t("t_view_all") }} <v-icon small class="ml-1">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-subtitle>
      </slot>
    </template>

    <template v-if="planners && planners.length">
      <v-list class="transparent pt-0" style="padding-bottom: 150px" flat>
        <v-list-item-group>
          <div v-for="item in planners.slice(0, takeNum)" :key="item.id">
            <v-list-item
              link
              @click="navigateToPlanner(item)"
              class="rounded mx-2 mb-2 grey lighten-3"
              :class="{ 'darken-4': $vuetify.theme.dark }"
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">
                  {{ item.plannerName }}
                </v-list-item-title>

                <v-list-item-subtitle class="mt-1">
                  <v-row no-gutters align="center">
                    <v-col cols="auto" class="mr-4">
                      <template v-if="item.selectedDueDate">
                        <v-menu offset-y v-model="item.selectDueDateOpen">
                          <template v-slot:activator="{ on, attrs }">
                            <span class="due-label text-caption mr-2 text-small pt-1">DUE:</span>
                            <v-btn @click.stop small class="px-2 due-date-btn" v-bind="attrs" v-on="on">
                              {{ formatDateTime(item.selectedDueDate.dueDateTime) }}
                              <v-icon small class="ml-1">mdi-chevron-down</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense>
                            <template v-for="dueDate in getDueDatesLimited(item, dueDateLimit)">
                              <v-list-item :key="dueDate.id" @click.stop="selectDueDate(item, dueDate)">
                                <v-list-item-title>
                                  {{ formatDateTime(dueDate.dueDateTime) }}
                                  <v-chip
                                    :color="statusColor(dueDate.status)"
                                    x-small
                                    text-color="white"
                                    class="ml-2 text-uppercase"
                                  >
                                    {{ getStatusText(dueDate.status) }}
                                    <template
                                      v-if="
                                        item.currentSubmission?.dueDateId == dueDate.id &&
                                        item.selectedDueDate?.completionPercentage
                                      "
                                    >
                                      - {{ item.currentSubmission?.completionPercentage || 0 }}%
                                    </template>
                                  </v-chip>
                                </v-list-item-title>
                              </v-list-item>
                            </template>

                            <!-- View All button if there are more than dueDateLimit dates -->
                            <template v-if="getTotalDueDates(item) > dueDateLimit">
                              <v-divider></v-divider>
                              <v-list-item @click.stop="showAllDueDates(item)">
                                <v-list-item-title class="text-center primary--text">
                                  View All ({{ getTotalDueDates(item) }})
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-list>
                        </v-menu>

                        <v-dialog v-model="item.showAllDatesDialog" max-width="400">
                          <v-card>
                            <v-card-title>All Due Dates</v-card-title>
                            <v-card-text>
                              <v-list dense>
                                <!-- Future due dates -->
                                <template v-if="item.futureDueDates.length">
                                  <v-subheader>Future Due Dates</v-subheader>
                                  <v-list-item
                                    v-for="dueDate in item.futureDueDates"
                                    :key="dueDate.id"
                                    link
                                    @click.stop="navigateToPlannerWithDate(item, dueDate)"
                                  >
                                    <v-list-item-title>
                                      {{ formatDateTime(dueDate.dueDateTime) }}
                                      <v-chip
                                        :color="statusColor(dueDate.status)"
                                        x-small
                                        text-color="white"
                                        class="ml-2 text-uppercase"
                                      >
                                        {{ getStatusText(dueDate.status) }}
                                        <template
                                          v-if="
                                            item.currentSubmission?.dueDateId == dueDate.id &&
                                            item.selectedDueDate?.completionPercentage
                                          "
                                        >
                                          - {{ item.currentSubmission?.completionPercentage || 0 }}%
                                        </template>
                                      </v-chip>
                                    </v-list-item-title>
                                    <v-list-item-action>
                                      <v-icon small>mdi-chevron-right</v-icon>
                                    </v-list-item-action>
                                  </v-list-item>
                                </template>

                                <!-- Past incomplete dates -->
                                <template v-if="item.pastDueDates.some((d) => d.status !== 2)">
                                  <v-divider></v-divider>
                                  <v-subheader>Past Due Dates</v-subheader>
                                  <v-list-item
                                    v-for="dueDate in item.pastDueDates.filter((d) => d.status !== 2)"
                                    :key="dueDate.id"
                                    link
                                    @click.stop="navigateToPlannerWithDate(item, dueDate)"
                                  >
                                    <v-list-item-title>
                                      {{ formatDateTime(dueDate.dueDateTime) }}
                                      <v-chip
                                        :color="statusColor(dueDate.status)"
                                        x-small
                                        text-color="white"
                                        class="ml-2 text-uppercase"
                                      >
                                        {{ getStatusText(dueDate.status) }}
                                        <template
                                          v-if="
                                            item.currentSubmission?.dueDateId == dueDate.id &&
                                            item.selectedDueDate?.completionPercentage
                                          "
                                        >
                                          - {{ item.currentSubmission?.completionPercentage || 0 }}%
                                        </template>
                                      </v-chip>
                                    </v-list-item-title>
                                    <v-list-item-action>
                                      <v-icon small>mdi-chevron-right</v-icon>
                                    </v-list-item-action>
                                  </v-list-item>
                                </template>

                                <!-- Completed dates -->
                                <template v-if="item.pastDueDates.some((d) => d.status === 2)">
                                  <v-divider></v-divider>
                                  <v-subheader>Completed</v-subheader>
                                  <v-list-item
                                    v-for="dueDate in item.pastDueDates.filter((d) => d.status === 2)"
                                    :key="dueDate.id"
                                    link
                                    @click.stop="navigateToPlannerWithDate(item, dueDate)"
                                  >
                                    <v-list-item-title>
                                      {{ formatDateTime(dueDate.dueDateTime) }}
                                      <v-chip
                                        :color="statusColor(dueDate.status)"
                                        x-small
                                        text-color="white"
                                        class="ml-2 text-uppercase"
                                      >
                                        {{ getStatusText(dueDate.status) }}
                                        <template
                                          v-if="
                                            item.currentSubmission?.dueDateId == dueDate.id &&
                                            item.selectedDueDate?.completionPercentage
                                          "
                                        >
                                          - {{ item.currentSubmission?.completionPercentage || 0 }}%
                                        </template>
                                      </v-chip>
                                    </v-list-item-title>
                                    <v-list-item-action>
                                      <v-icon small>mdi-chevron-right</v-icon>
                                    </v-list-item-action>
                                  </v-list-item>
                                </template>
                              </v-list>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn text @click="item.showAllDatesDialog = false">Close</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <v-chip
                          :color="statusColor(item.selectedDueDate.status)"
                          x-small
                          text-color="white"
                          class="ml-2 text-uppercase"
                        >
                          {{ getStatusText(item.selectedDueDate.status) }}
                          <template
                            v-if="
                              item.currentSubmission?.dueDateId == item.selectedDueDate?.id &&
                              item.selectedDueDate?.completionPercentage
                            "
                          >
                            - {{ item.currentSubmission?.completionPercentage || 0 }}%
                          </template>
                        </v-chip>
                      </template>
                      <div v-else class="no-due-date">
                        Open Ended
                        <v-chip
                          :color="statusColor(item.currentSubmission?.status ? item.currentSubmission?.status : 0)"
                          x-small
                          text-color="white"
                          class="ml-2 text-uppercase"
                        >
                          {{ getStatusText(item.currentSubmission?.status ? item.currentSubmission?.status : 0) }}
                          <template v-if="item.currentSubmission?.completionPercentage">
                            - {{ item.currentSubmission?.completionPercentage }}%
                          </template>
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </template>
  </div>
</template>

<script>
import { PlannerService } from "@services";
import helpers from "@/mixins/helpers";
import { DateTime } from "luxon";

export default {
  name: "PlannerList",

  mixins: [helpers],

  props: {
    takeNum: {
      type: Number,
      default: 5,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      default: () => ({
        page: 1,
        itemsPerPage: 25,
        sortBy: ["id"],
        sortDesc: [true],
      }),
    },
  },

  computed: {
    showPagination() {
      return this.totalItems > this.options.itemsPerPage;
    },
  },

  data: () => ({
    loading: false,
    planners: null,
    dueDateLimit: 4,
  }),

  methods: {
    navigateToPlanner(item) {
      let path = `/planner/${item.plannerId}/${item.id}`;
      if (item.currentSubmission && !item.selectedDueDate) {
        path += `/0/${item.currentSubmission.id}`;
      } else if (item.selectedDueDate?.id) {
        path += `/${item.selectedDueDate.id}`;
        if (item.selectedDueDate.submissionId) {
          path += `/${item.selectedDueDate.submissionId}`;
        }
      }
      this.$router.push(path);
    },

    getDueDates(item) {
      const now = DateTime.local();
      const futureDueDates = [];
      const pastDueDates = [];
      const completedDueDates = [];

      item.dueDates.forEach((date) => {
        const dateTime = DateTime.fromISO(date.dueDateTime, { zone: "utc" }).toLocal();
        const processedDate = {
          ...date,
          dateTime,
        };

        // Separate completed due dates
        if (date.status === 2) {
          // Completed status
          completedDueDates.push(processedDate);
        }
        // Future due dates (not completed)
        else if (dateTime > now) {
          futureDueDates.push(processedDate);
        }
        // Past due dates (not completed)
        else if ([0, 1, 3].includes(date.status)) {
          // Pending, InProgress, or Overdue
          pastDueDates.push(processedDate);
        }
      });

      return {
        futureDueDates: futureDueDates.sort((a, b) => a.dateTime.valueOf() - b.dateTime.valueOf()),
        pastDueDates: [
          ...pastDueDates.sort((a, b) => b.dateTime.valueOf() - a.dateTime.valueOf()),
          ...completedDueDates.sort((a, b) => b.dateTime.valueOf() - a.dateTime.valueOf()),
        ],
      };
    },

    selectDueDate(item, dueDate) {
      item.selectedDueDate = dueDate;
      item.selectDueDateOpen = false;
    },

    async loadPlanners() {
      this.loading = true;
      try {
        const { page, itemsPerPage, sortBy, sortDesc } = this.options;
        const params = {
          "paging.orderByField": sortBy.length ? sortBy[0] : "id",
          "paging.ascending": sortBy.length ? !sortDesc[0] : false,
          "paging.skipNum": (page - 1) * (itemsPerPage === 9999 ? 0 : itemsPerPage),
          "paging.takeNum": this.preview ? this.takeNum : itemsPerPage,
          contentwildcardsearch: this.search,
        };

        const r = await PlannerService.getPlannerAssignments(params);
        this.totalItems = r.meta.numTotal;
        this.$emit("update:total-items", r.meta.numTotal);
        const plannerAssignments = r.data?.data ? r.data.data : r.data;

        this.planners = plannerAssignments.map((planner) => {
          const { futureDueDates, pastDueDates } = this.getDueDates(planner);

          let selectedDueDate = null;
          if (planner.currentSubmission && planner.currentSubmission.dueDateId) {
            selectedDueDate = planner.dueDates.find((dueDate) => dueDate.id === planner.currentSubmission.dueDateId);
            selectedDueDate.completionPercentage = planner.currentSubmission.completionPercentage;
          }

          // If no current submission or no matching due date, fallback to the next due date
          if (!selectedDueDate) {
            selectedDueDate =
              futureDueDates.length > 0 ? futureDueDates[0] : pastDueDates.length > 0 ? pastDueDates[0] : null;
          }

          return {
            ...planner,
            futureDueDates,
            pastDueDates,
            selectedDueDate,
            selectDueDateOpen: false,
            showAllDatesDialog: false,
          };
        });

        this.$emit("update:total-items", r.meta.numTotal);
      } catch (error) {
        console.error("Error loading planners:", error);
      } finally {
        this.loading = false;
      }
    },

    getDueDatesLimited(item) {
      const allDates = [
        ...item.futureDueDates,
        ...item.pastDueDates.filter((d) => d.status !== 2),
        ...item.pastDueDates.filter((d) => d.status === 2),
      ];
      return allDates.slice(0, this.dueDateLimit);
    },

    getTotalDueDates(item) {
      return item.futureDueDates.length + item.pastDueDates.length;
    },

    showAllDueDates(item) {
      item.showAllDatesDialog = true;
      item.selectDueDateOpen = false;
    },

    navigateToPlannerWithDate(item, dueDate) {
      item.showAllDatesDialog = false;
      let path = `/planner/${item.plannerId}/${item.id}/${dueDate.id}`;
      if (dueDate.submissionId) {
        path += `/${dueDate.submissionId}`;
      }
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss">
.planners-list {
  .due-label {
    opacity: 0.7;
  }
  .no-due-date {
    font-weight: normal;
  }
  .due-date-btn {
    text-transform: none;
    letter-spacing: normal;
    font-family: "Roboto", sans-serif !important;
  }
  .menu-list {
    .v-menu__content {
      max-height: 400px !important;
      overflow-y: auto !important;
      overflow-x: hidden;
      will-change: transform;
      contain: content;

      // Optional: add custom scrollbar styling
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }
    }
  }

  .scrollable-menu {
    overflow-y: auto;
  }
}
</style>
