<template>
  <div class="planners">
    <template v-if="!loading">
      <v-row class="mx-1 mb-4 mt-0">
        <v-col cols="12" sm="8" lg="6" xl="5">
          <p class="headline mb-0">Planners</p>
          <v-text-field
            class="my-0 pt-2"
            v-model="search"
            clearable
            hide-details
            single-line
            prepend-inner-icon="mdi-magnify"
            label="Search"
            @input="debouncedSearch"
          ></v-text-field>
        </v-col>
      </v-row>

      <PlannerList
        ref="plannerList"
        :take-num="options.itemsPerPage"
        :preview="false"
        :search="search"
        :options="options"
        @update:options="handleOptionsUpdate"
        @update:total-items="handleTotalItems"
      />

      <v-row v-if="showPagination" justify="center" class="pa-4">
        <v-col cols="auto">
          <v-pagination
            v-model="options.page"
            :length="Math.ceil(totalItems / options.itemsPerPage)"
            :total-visible="7"
            @input="handlePageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import PlannerList from "@/components/planners/PlannerList.vue";
import helpers from "@/mixins/helpers";
import { debounce } from "lodash";

export default {
  name: "SafetyPlanners",

  metaInfo: {
    title: "Safety Planners",
  },

  components: {
    PlannerList,
  },

  mixins: [helpers],

  computed: {
    showPagination() {
      return this.totalItems > this.options.itemsPerPage;
    },
  },

  data() {
    return {
      search: "",
      loading: false,
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["id"],
        sortDesc: [true],
      },
    };
  },

  methods: {
    debouncedSearch: debounce(function () {
      this.options.page = 1;
      this.$refs.plannerList?.loadPlanners(true);
    }, 500),

    handleOptionsUpdate(newOptions) {
      this.options = { ...newOptions };
      this.$refs.plannerList?.loadPlanners(true);
    },

    handleTotalItems(total) {
      this.totalItems = total;
    },

    handlePageChange(page) {
      this.options.page = page;
      this.$refs.plannerList?.loadPlanners(true);
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.plannerList?.loadPlanners(true);
    });
  },

  watch: {
    options: {
      deep: true,
      handler() {
        if (this.$refs.plannerList) {
          this.$refs.plannerList.loadPlanners(true);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.planners {
  .due-date-btn {
    text-transform: none;
    letter-spacing: normal;
    font-family: "Roboto", sans-serif !important;
  }
  .no-due-date {
    font-weight: normal;
  }
}
</style>
